import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import {myaddroute} from '/src/utils/routeraction.js';
import guide from './components/guide/index';
import loader from './components/loader/index';



myaddroute()
if(navigator.userAgent.toLowerCase().indexOf('window')==-1&&navigator.userAgent.toLowerCase().indexOf('pad')==-1){
        window.location.href='https://xinhuang.net.cn/wap'
}

router.beforeEach( (to,from,next)=>{
    // console.log(to);

        store.state.beforerouter = false;
        store.state.mylink = to.path;
        next();

})


createApp(App)
    .use(router)
    .use(store)
    .use(guide)
    .use(loader)
    .mount('#app')


