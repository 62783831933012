<template>
  <div class="loader-shadow">
    <div class="loader">
    </div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props:{
    shadow:{
      default: false,
      type:Boolean,
    }
  },
}
</script>

<style scoped>
.loader-shadow{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  z-index:299;
  transform: translate(-50%,-50%);
}
.loader{
  margin: -65px 0 0 -65px;
  position: relative;
  left: 50%;
  top: 50%;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: solid 3px transparent;
  border-top-color: rgb(217,185,33);
  animation: spin 1.5s infinite linear;
  animation-delay: 0s;
}
.loader:before{
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  border-radius: 50%;
  border: solid 3px transparent;
  border-top-color: rgb(248,206,9);
  animation: spin 1s infinite linear;
  animation-delay: 0.6s;
}
.loader:after{
  content: '';
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  top: 15px;
  border-radius: 50%;
  border: solid 3px transparent;
  border-top-color: rgb(232,209,98);
  animation: spin 1.8s infinite linear;
  animation-delay: 0.3s;
}
@keyframes spin {
  100%{
    transform: rotateZ(360deg);
  }
}
</style>