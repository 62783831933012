export function base_url(){
    return 'https://pc.api.xinhuang.net.cn'
    // return 'http://192.168.100.196:7001'
}

export function cdn_url() {

    return 'http://xinhuang.cdn.xinhuang.net.cn'

}
