// import Vue from 'vue'
// import VueRouter from 'vue-router'
import home from '../views/home.vue'
// import xh from '../views/xh.vue'
// import service from '../views/service.vue'
// import about from '../views/about.vue'
// import Contact from '../views/Contact.vue'
import {createRouter,  createWebHistory} from 'vue-router'
// Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect:'/home',
    },
    {
        path: '/home',
        name: '首页',
        component: home,
        meta:{index:0},
    },
    // {
    //     path: '/xh',
    //     name: '鑫嵘',
    //     component: xh,
    //     meta:{index:1},
    // },
    // {
    //     path: '/service',
    //     name: '服务',
    //     component: service,
    //     meta:{index:2},
    // },
]



const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
