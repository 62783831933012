import {createStore} from "vuex";






export default createStore({
  state: {
    //左移右移动画
    transitionName:'',
    //选中导航栏
    mylink:'',
    //路由跳转之前删除diybar
    beforerouter:true,

    //路由列表，用来动态加载路由
    routerlist:[
      {
        router:'/home',
        name:'首页',
        index:0,
      },
      {
        router:'/xh',
        name:'鑫嵘',
        index:1,
      },
      {
        router:'/service',
        name:'服务',
        index:2,
      },
      // {
      //   router:'/product',
      //   name:'产品',
      //   index:3,
      // },
      {
        router:'/case',
        name:'案例',
        // index:4,
        index:3,
      },
      // {
      //   router:'/news',
      //   name:'资讯',
      //   // index:5,
      //   index:4,
      // },
      {
        router:'/xhhc',
        name:'汇创',
        // index:6,
        index:4,
      },
    ],
  },
  mutations: {
  },
  actions: {
    // actionGetPermissions({commit}) {
    //   return new Promise((resolve) => {
    //
    //     commit('_getPermissionsList', () => {
    //       resolve(true);
    //     });
    //
    //   });
    //
    // }

  },
  modules: {
  },
})
