<template>
        <div class="scrolllayout" @click="handleswiperchoose">
          <loader v-if="loading||loadingprevent"></loader>
<!--                <video class="bac" src="@/img/home.mp4" loop muted autoplay></video>-->
<!--            <img src="@/img/p1.jpg" class="bac" alt="">-->
                <canvas id="canvas" class="bac" style="z-index: 0" v-if="resetcanvas"></canvas>
<!--            进入页面预加载图片-->
<!--                <div class="bac" style="display: flex;flex-direction: row;align-items: center;justify-content: center;z-index: 99;font-size: 25px">{{i}} {{err}}</div>-->

                <img :src="cdn_url+'/public/images/home/home000.webp'" class="bac" style="z-index: -2" alt="">



<!--          <div v-for="(item,index) in datalist" :key="item.id">-->
<!--            <img-->
<!--                @load="handleImgOnLoad(index)"-->
<!--                @error="handleError($event,index)"-->
<!--                :src="base_url+item.imgUrl"-->
<!--                class = "imgclass"-->
<!--                v-for="(item,index) in datalist" :key="item.id"-->
<!--                style="display: none"-->
<!--            />-->
<!--            <div v-else class = "imgclass"></div>-->
<!--          </div>-->
<!--                <img-->
<!--                        @load="handleImgOnLoad(index)"-->
<!--                        @error="()=>{handleError(index)}"-->
<!--                        v-for="(item,index) in datalist" :key="item.id"-->
<!--                        :src="base_url+item.imgUrl"-->
<!--                        class = "imgclass"-->
<!--                        style="display: none"-->
<!--                />-->
                <div class="shadow" >
<!--                  <div style="position: fixed;left: 0;right: 0;bottom: 0;top: 0;background-color: white;z-index: 100;overflow: auto;color: black">-->
<!--                    <div style="margin-top:300px;margin-left: 200px;font-size: 40px">{{myerr}}</div>-->
<!--                  </div>-->
                  <div class="swiper">
                        <div class="swiper-unit" :style="'animation-delay:'+v.delay" v-for="(v,i) in swiper" :key="i">
                            <div class="swiper-unit-left"></div>
                            <div class="swiper-unit-right">
                                <div class="swiper-unit-right-unit" v-for="(c,d) in v.text" :key="d">
                                  {{c}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <a @click="handlerouter('/xh')" class="more" @click.stop>了解更多{{myerr}}</a>
                        <div class="bottom-right">
                            <div class="bottom-right-left"></div>
                            <div class="swiper1" >
                                <div class="swiper1-unit" v-for="(v,i) in swiper1" :style="'animation-delay:'+v.delay" :key="i">
                                  {{v.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bei" @click.stop>
                    <a class="gaj" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44051102000703" target="_blank" style="color: white;">
                      <img src="@/img/beian.png" class="gaj-img" alt="">
                      <div>粤公网备44051102000703号</div>
                    </a>
                    <a class="icp" href="https://beian.miit.gov.cn/" target="_blank" style="color: white;">
                      粤ICP备15106449号
                    </a>
                </div>
            <!--        <button ref="test" >sss</button>-->
        </div>

</template>

<script>
    // import ScreenFull from 'screenfull'
    // import guide from "../components/guide";
    // import imgorder from '../api/imgorder'
    import {base_url, cdn_url} from "../api/base_url";
    import axios from "axios"
    export default {
        // components: {guide},
        data(){
            return{
                //当前图片序列
                datalist:[],
                imageData:{},



                //控制换图
                i:0,
                //定时器
                timer:'',
                //总数
                total:0,
                //当前加载页数
                page:1,
                //一次加载几张
                pageSize:60,
                //canvas
                canvas:null,
                ctx:null,
                resetcanvas:1,
                //ipad端错误
                err:0,
                base_url:base_url(),
                cdn_url:cdn_url(),
                //界面宽度的一半
                resizeX:0,
                preventcontact:0,
                swiperchoose:0,


                //加载
                loading:false,
              //阻止断续出现加载中
                loadingprevent:0,


                swiper:[
                    {
                        text:['以客户价值为核心','打造一站式数字化链路服务'],
                        delay:0,
                    },
                    {
                        text:['以客户成功为方向','不断催生出每一个创意想法'],
                        delay:'8s',
                    },
                ],
                swiper1:[
                    {
                        text:'我们一直致力于为企业、机构、个人提供一系列“数字化+品牌建设”整体解决方案；以客户为核心，多维度优化技术服务流程，综合提升服务水平和客户满意度。',
                        delay:0,
                    },
                    {
                        text:'深度分析客户的每个需求并验证技术功能，为客户带来实际成效，不断增强增值服务，加强创意构思的输出，赋能企业的价值与活力，服务品牌战略目标。',
                        delay:'8s',
                    },
                ],
                myerr:'',
                // routerindex:0,
            }
        },
        props:{
          componentData:{
            default:0,
            type:Number,
          },
        },
        beforeUnmount(){
            clearInterval(this.timer)
        },
        created() {

            // this.$nextTick(() => {



          window.addEventListener('resize',this.resize)
          this.resize()

                // document.onmousedown=()=>{
                // }
            // });

            setTimeout(()=>{
                window.addEventListener('mousewheel',this.handlemouse)
            },500)


            //监听按键
            setTimeout(()=>{
                    window.addEventListener('keydown',this.keydown)
            },500)
            this.getdatalist().then(()=>{
              this.canvas = document.getElementById("canvas");
              this.ctx = this.canvas.getContext("2d");
                    this.drawcanvas()
            })
        },

        // watch:{
        //   $route(to,from){
        //       if(to.meta.index>from.meta.index){
        //           this.routerindex= 0
        //       }else{
        //           this.routerindex= 1
        //       }
        //   },
        // },
        watch:{
            swiperchoose:function (e) {
                if(e>0){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index+1].router)
                    return
                }else if(e<0){
                    this.handlerouter('/xhhc')
                    return
                }
            },
            loading:function (e){
              if(e&&!this.loadingprevent)  {
                this.loadingprevent=true
                setTimeout(()=>{

                    this.loadingprevent = false

                },3000)
              }
            },
        },
        methods: {
          // handleError(err,index) {
          //   axios.get(this.base_url+this.datalist[index]['imgUrl']).then(()=>{
          //   }).catch(()=>{
          //   })
          //
          //
          //     this.datalist[index].load = '-1';
          //     // this.datalist.forEach(item=>{
          //     //   item.myshow=true
          //     // })
          // },
          handleImgOnLoad(index) {
            // if(index==428){
            // }



              this.datalist[index]['load'] = true;



          },
          resize(){
            if(this.componentData){
              this.resizeX = this.componentData/2
              return
            }
            this.resizeX = document.body.clientWidth/2
          },
          //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
          handleswiperchoose(e) {
            //监听是否全屏
            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
            if(!isFullScreen){
              return;
            }
            if(this.preventcontact){
              return
            }
            this.preventcontact=1
            let X = e.clientX
            // if(this.componentData){
            //     X = e.clientY
            // }
            if(X>=this.resizeX){
              this.swiperchoose++
            }else{
              this.swiperchoose--
            }
            setTimeout(()=>{
              this.preventcontact=0
            },500)
          },
            resizeCanvas(){
              // if(!this.datalist[this.i]['myshow']){
              //   this.datalist[this.i]['myshow'] =true
              // }
                this.$nextTick(()=>{



                    // var img = document.getElementsByClassName('imgclass')
                    this.canvas.width = document.body.clientWidth;
                    this.canvas.height = 0.56*document.body.clientWidth;
                  try {
                    // img[this.i].onload = () => {
                    //     this.ctx.drawImage(img[this.i], 0, 0, this.canvas.width, this.canvas.height);
                    //   };
                    //   if (this.datalist[this.i].load === false) {
                    //
                    //     throw '';
                    //   }



                    // if (this.datalist[this.i].load === '-1') {
                    //   this.i = 0
                    //   return;
                    // }



                    //   this.loading = false
                    //   this.ctx.drawImage(img[this.i], 0, 0, this.canvas.width, this.canvas.height);






                    // if (this.datalist[this.i].load === false) {
                    //
                    //   throw '';
                    // }
                    if (!this.imageData[this.datalist[this.i].id]||this.loadingprevent) {
                      throw '';
                    }






                    // if (this.datalist[this.i].load === '-1') {
                    //   this.i = 0
                    //   return;
                    // }





                    // if(!this.loadingprevent&&this.loading){
                      this.loading =false
                      // this.loadingprevent = true
                      // setTimeout(()=>{
                      //   this.loadingprevent = false
                    //   },2000)
                    // }

                    this.ctx.drawImage(this.imageData[this.datalist[this.i].id], 0, 0, this.canvas.width, this.canvas.height);





                    // if(this.i>=100){
                    //   this.datalist[this.i-99]['myshow']=false
                    // }


                    }catch (err) {



                      // if(!this.loadingprevent&&!this.loading){
                        this.loading = true
                      //   this.loadingprevent = true
                      //   setTimeout(()=>{
                      //     this.loadingprevent = false
                      //   },2000)
                      // }






                    let i=this.i-1




                        if(i<0){
                        var img1 = document.getElementsByClassName('bac')
                        // img1[0].onload = () => {
                        //   this.ctx.drawImage(img1[0], 0, 0, this.canvas.width, this.canvas.height);
                        // };

                        this.ctx.drawImage(img1[0], 0, 0, this.canvas.width, this.canvas.height);
                        return
                      }

                    // img[i].onload = () => {
                        //   this.ctx.drawImage(this.img[i], 0, 0, this.canvas.width, this.canvas.height);
                        // };

                        this.ctx.drawImage(this.imageData[this.datalist[i].id], 0, 0, this.canvas.width, this.canvas.height);
                        return

                    }



                        // var imgUrl = canvas.toDataURL('image/webp')
                        if(this.i == (this.page*this.pageSize-this.pageSize-this.pageSize/2)){
                            this.getdatalist()
                        }
                        // let data = {
                        //     src,
                        //     name:this.imgorder[this.i].name
                        // }
                        // axios.post('http://192.168.100.151:7001/imgorder',{data}).then(()=>{
                        // })
                    this.i = this.i+1

                })
                // if(this.i ==this.page*this.pageSize-this.pageSize-this.pageSize/2){
                //     this.getImage()
                // }
            },
            getdatalist(){

                return new Promise((resolve) => {
                  if((this.page*this.pageSize-this.pageSize>=this.total)&&(this.total!=0)){
                    resolve(true)
                    return
                  }
                    let page = this.page
                    let pageSize = this.pageSize
                    let params={
                        page,
                        pageSize,
                    }

                    let addurl = '/imgorder'
                    if(this.componentData){
                      addurl = '/imgorder/ipad'
                    }

                        axios.get(this.base_url+addurl,{
                          headers:{
                            'base':'gw'
                          },
                          params,
                        }).then((res)=>{
                            let datalist = this.datalist
                            datalist  = datalist.concat(res.data.data.map((item)=>{

                              // item.load = false;



                              let pathimg =new Image()
                                pathimg.onload=()=>{
                                  this.imageData[item.id] =pathimg


                                }
                                pathimg.src = this.cdn_url+item.imgUrl



                              // pathimg.onerror = ()=>{
                              //   item.load = '-1'
                              // }







                              return item;

                            }))
                            this.total = res.data.total
                            this.datalist = datalist
                            this.page++
                          resolve(true);

                        })
                })
            },
          drawcanvas() {
            // this.resetcanvas = 1
            this.$nextTick(() => {


              // this.canvas = document.getElementById("canvas");
              // this.ctx = this.canvas.getContext("2d");
              this.timer = setInterval(() => {

                if (this.i >= this.total) {

                  // this.resetcanvas = 0
                  // this.canvas = null;
                  // this.ctx = null;
                  clearInterval(this.timer);
                  setTimeout(() => {
                    this.i = 0
                    this.drawcanvas()
                  }, 500)
                  return
                } else {


                  this.resizeCanvas();
                }
              }, 50)
            })
            // var img1 = this.$refs.myref1[0];


            // const timer = setInterval(()=>{
            //   if(i===1){
            //     // (this.img.length-1)
            //     clearInterval(timer)
            //   }

            //   i++;
            // },35)
          },
            getImage() {
                // for(let i =0 ;i<=431/10+1;i++){
                //     for(let j = 0,k = 0;j<k+10;j++){
                //
                //     }
                // }

                // return new Promise((resolve) => {
                //
                //     for(let i = 0;i<431;i++){
                //         this.datalist.push({
                //             imgUrl:require('@/img/home/' + this.imgorder[i].name + '.jpg')
                //         })
                //     }
                //     resolve(true);
                // })


                // return new Promise((resolve) => {
                //     for(let i = this.page*this.pageSize;i<this.page*this.pageSize+this.pageSize;i++){
                //         if(this.page == this.allpage&&i>1||this.page > this.allpage){
                //             return
                //         }
                //         this.datalist.push({
                //             imgUrl:require('@/img/home/' + this.imgorder[i].name + '.jpg')
                //         })
                //     }
                //     this.page++
                //     resolve(true);
                // })




                // this.imgList1.forEach(item => {
                //   item.imgUrl = require('@/components/images/APP1/APP6/' + item.name + '.png')
                // })
            },

            handlemouse(e){
                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++
                }else if(e.deltaY<0){
                    this.swiperchoose--
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },


            // mymouse(){
            //     this.$nextTick(()=>{
            //         this.$refs.test.click()
            //     })
            // },
            // handleclick(){
            // },
        }
    }
</script>

<style scoped>



    .swiper{
        position: absolute;
        left: 2%;
        right: 0;
        top: 50%;
    }
    .swiper-unit{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: var(--b-t-size);
        position: absolute;
        width: 675px;
        animation-name: turnleft;
        animation-duration: 16s;
        animation-iteration-count:infinite;
        left: 100%;
        transform: translateY(-50%);
        right: 0;
    }

    .swiper-unit-left{
        width: 158px;
        height: 8px;
        background: var(--s-color1);
        margin-right: 25px;
    }



    .swiper-unit-right-unit{
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 80%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .swiper-unit-right-unit:first-of-type{
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 95%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 13%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .more{
        margin-left: 2%;
        text-decoration: none;
        color: rgba(255,255,255,0.9);
        font-size: var(--s-t-size);
        background-image: linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,123,211,0.4));
        padding: 0 62px;
        height: 63px;
        line-height: 63px;
      cursor: pointer;
    }
    .bottom-right{
        display: flex;
        flex-direction: row;
        width: 413px;
        font-size: var(--s-c-size);
        color: rgba(255,255,255,0.9);
        margin-right: 8%;
    }
    .bottom-right-left{
        background: rgba(255,255,255,0.9);
        width: 1.5px;
        height: 40px;
        margin-right: 34px;
        margin-top: 7px;
    }
    .swiper1{
        line-height: 2;
        position: relative;
        width: 100%;
    }
    .swiper1-unit{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        animation-name: turnup;
        animation-duration: 16s;
        animation-iteration-count:infinite;
        opacity: 0;
        text-indent: 28px;
    }
    @keyframes turnleft {
        0%{
            left: 100%;
        }
        20%{
            left: 0;
        }
        40%{
            left: 0;
        }
        50%{
            left: -100%;
        }
        100%{
            left: -100%;
        }
    }
    @keyframes turnup {
        0%{
            opacity: 0;
        }
        5%{
            opacity: 1;
        }
        45%{
            opacity: 1;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 0;
        }
    }
    .bei{
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0.7;
    }
    .icp{
      font-size: 10px;
    }
    .gaj{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 10px;
      margin-right: 20px;
    }
    .gaj-img{
      width: 15px;
      height: 15px;
    }
</style>
