<template>
    <div class="nav1" :style="nav1Data?'font-size:13px':''">
        <div class="nav1-left">
            <div :class="nav1Data?'nav1-left-left1':'nav1-left-left'">
                <img src="../img/logo.png" alt="">
            </div>
        </div>
        <div class="list">
            <div @click="handlerouter(v.link)" :class="$store.state.mylink==v.link?'mylink':'list-unit'"  :style="nav1Data?'width:auto;background-size:100% 105px':''" v-for="(v,i) in navlist" :key="i">
                {{v.name}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                mylink:'',
                navlist:[
                    {
                        name:'首页',
                        link:'/home',
                    },
                    {
                        name:'进入鑫嵘',
                        link:'/xh',
                    },
                    {
                        name:'服务中心',
                        link:'/service',
                    },
                    // {
                    //     name:'产品中心',
                    //     link:'/product',
                    // },
                    {
                        name:'案例中心',
                        link:'/case',
                    },
                    // {
                    //     name:'资讯中心',
                    //     link:'/news',
                    // },
                    {
                        name:'鑫嵘科技',
                        link:'/xhhc',
                    },
                ],
            }
        },
        props:{
            nav1Data:{
                default:0,
                type:Number,
            }
        },
        created() {
            this.mylink= window.location.pathname
        },
        methods:{
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
        }
    }
</script>

<style scoped lang="scss">
    .nav1{
        height: 110px;
        width: 100%;
        font-size: var(--s-t-size);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        color: rgba(255,255,255,0.9);
        z-index:10;
        @media screen and (max-width: 1250px) {
            font-size:var(--b-c-size);
        }
    }
    .nav1-left{
        padding-left: 4%;
        padding-top: 30px;
        margin-right: 20px;
    }
    .list{
        padding-right: 15%;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1400px) {
            padding-right: 9%;
        }
    }
    .list-unit,.mylink{
        padding-left: 26px;
        padding-right: 26px;
        padding-top: 30px;
        cursor: pointer;
        text-align: center;
        width: 85px;
        @media screen and (max-width: 1650px) {
            width: 82px;
        }
    }
    .mylink{
        animation: colordown 0.3s 1 linear forwards;
        background-image: linear-gradient(to bottom,rgba(0,123,211,0.4),rgba(0,0,0,0));
        background-repeat: no-repeat;
    }
    @keyframes colordown {
        0%{
            height: 0%;
        }
        100%{
            height: 100%;
        }
    }
    .nav1-left-left{
        width:156.1px;
        height: 40px;
    }
    .nav1-left-left1{
      width: 117px;
      height: 30px;
      margin-left: 50px;
    }
</style>
