<template>
    <div>

<!--        <div class="guide" v-show="change" style="width: 100%;height: 100%" @tap.stop="()=>{}" @click="handleChange(0)">-->

<!--        </div>-->
        <div class="guide-hover"  @mousemove="handleChange(1)" @mouseleave="handleChange(0)" >
            <div class="guide-text"
                 @mousemove.stop
                 @click.stop="handleChange(-1)"
                 :style="change?'bottom: 10%;box-shadow: 0 0 0 rgba(0,123,211,0.6);padding: 40px 20px;background:rgba(0,123,211,0.7)':
             'bottom:-25%;box-shadow: 0px 0px 10px 10px rgba(0,123,211,0.6);padding: 10px 20px;background:linear-gradient(to bottom,rgba(0,123,211,0.5),rgba(0,123,211,0.1));'">
                浏览指南
            </div>
            <div class="guide-information" :style="change?'right:10%':'right:-300%'">
                <div class="guide-information-item" v-if="!guideData">
                    <div class="guide-information-item-left">
                        <div class="mouse">
                            <div class="mouse-top">
                                <div class="mouse-left"></div>
                                <div class="mouse-center"></div>
                                <div class="mouse-right"></div>
                            </div>
                            <div class="mouse-down"></div>
                        </div>
                    </div>
                    <div class="guide-information-item-right">
                        <div>
                          鼠标左键点击页面进入全屏；进入全屏后，点击页面左边会切换上个页面，点击页面右边会切换下个页面。
                        </div>
                    </div>
                </div>
                <div class="guide-information-item" v-if="!guideData">
                    <div class="guide-information-item-left">
                        <div class="mouse">
                            <div class="mouse-top">
                                <div class="mouse-left1"></div>
                                <div class="mouse-center1"></div>
                                <div class="mouse-right"></div>
                            </div>
                            <div class="mouse-down"></div>
                        </div>
                    </div>
                    <div class="guide-information-item-right">
                     鼠标滚轮向下滚动切换下个页面，向上滚动切换上个页面。
                    </div>
                </div>
              <div class="guide-information-item" v-if="!guideData">
                <div class="guide-information-item-left">
                  <div class="direction">
                    <div class="direction-top" style="padding: 5px">Esc</div>
                  </div>
                </div>
                <div class="guide-information-item-right">
                  键盘esc键 退出全屏。
                </div>
              </div>
                <div class="guide-information-item" v-if="!guideData">
                    <div class="guide-information-item-left">
                        <div class="direction">
                            <div class="direction-top">↑</div>
                            <div class="direction-bottom">
                                <div class="direction-left">←</div>
                                <div class="direction-down">↓</div>
                                <div class="direction-right">→</div>
                            </div>
                        </div>
                    </div>
                    <div class="guide-information-item-right">
                      键盘方向键 ← 切换上个页面，→ 切换下个页面，↑ 切换导航栏的上个页面，↓ 切换导航栏的下个页面。
                    </div>
                </div>
                <div class="guide-information-item" v-if="guideData">
                  <div class="guide-information-item-left" style="position: relative">
                    <div class="pointer-top-item-top"></div>
                    <div class="pointer">
                      <div class="pointer-top">
                          <div class="pointer-top-item">
                            <div class="pointer-top-item-bottom"></div>
                          </div>
                          <div class="pointer-top-item"></div>
                          <div class="pointer-top-item"></div>
                          <div class="pointer-top-item"></div>
                        </div>
                        <div class="pointer-bottom"></div>
                    </div>
                  </div>
                  <div class="guide-information-item-right">
                    触摸页面进入全屏；进入全屏后，触摸屏幕左边会切换上个页面，触摸屏幕右边会切换下个页面。
                  </div>
                </div>
              <div style="margin-top: 10px;text-indent: 24px">提示：浏览页面时会自动开启全屏浏览，以提高浏览体验。</div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data(){
            return{
                change:1,//1为显示指南，2为隐藏指南
            }
        },
      props:{
        guideData:{
          default:0,
          type:Number,
        }
      },
      watch:{
        $route(){
          this.change = 0
        }

      },
        created() {
            setTimeout(()=>{
                if(this.change){
                    this.change = 0
                }
            },10000)
        },
      methods:{
            handleChange(status) {
                if (status===-1&&this.guideData) {
                    this.change = this.change === 1 ? 0 : 1;
                    return;
                }

                this.change = status;

            }
        }
    }
</script>

<style scoped>
    .guide-text{
        color: white;
        position: absolute;
        right: 20%;
        bottom: -50%;
        /*bottom: 2%;*/


        border-radius: 100%;
        font-size: var(--s-c-size);
        box-shadow: 0px 0px 10px 10px rgba(0,123,211,0.6);
        /*box-shadow: 0 0 0 rgba(0,123,211,0.6);*/
        transition: 0.3s ease-in;

    }
    .guide{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        width: 300px;
        height: 60px;
    }
    .guide-hover{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 999;
        width: 130px;
        height: 120px;
        cursor: pointer;
    }



    .guide-information{
        position: absolute;
        bottom:100%;
        /*right: 2%;*/
        right: -300%;
        line-height: 2;
        font-size: 12px;
        border-radius: 11px;
        transition: 0.3s  ease-in;
        width: 350px;
        padding: 18px;
        background-color: rgba(70,71,76,0.9);
    }


    .guide-information-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
    }
    .guide-information-item:first-of-type{
        margin-top: 0;
    }
    .guide-information-item-left{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;
    }
    .guide-information-item-right{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10px;
        width: 250px;
        text-indent: 24px;
    }
    .mouse{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mouse-top{
        display: flex;
        flex-direction: row;
        position: relative;
    }
    .mouse-left,.mouse-left1{
        height: 20px;
        width: 20px;
        border-top-left-radius: 20px;
        border-top:2px solid white;
        border-left:2px solid white;
        border-bottom:1px solid white;
        border-right:1px solid white;
    }
    .mouse-left{
        animation-name:mouseleft;
        animation-iteration-count: infinite;
        animation-duration: 1s;
    }
    @keyframes mouseleft {
        0%{
            background-color: rgba(70,71,76,0.9);
        }
        20%{
            background-color: rgba(70,71,76,0.9);
        }
        30%{
            background-color: rgba(0,123,211,0.7);
        }
        40%{
            background-color: rgba(0,123,211,0.7);
        }
        45%{
            background-color: rgba(70,71,76,0.9);
        }
        100%{
            background-color: rgba(70,71,76,0.9);
        }
    }
    @keyframes directiondown {
        0%{
            background-color: rgba(70,71,76,0.9);
        }
        20%{
            background-color: rgba(70,71,76,0.9);
        }
        25%{
            background-color: rgba(0,123,211,0.7);
        }
        30%{
            background-color: rgba(0,123,211,0.7);
        }
        35%{
            background-color: rgba(70,71,76,0.9);
        }
        100%{
            background-color: rgba(70,71,76,0.9);
        }
    }
    .mouse-right{
        height: 20px;
        width: 20px;
        border-top-right-radius: 20px;
        border-top:2px solid white;
        border-left:1px solid white;
        border-bottom:1px solid white;
        border-right:2px solid white;
    }
    .mouse-center,.mouse-center1{
        height: 12px;
        width: 3px;
        border-radius: 5px;
        border: 2px solid white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .mouse-center{
        background-color: var(--s-color1);
    }
    @keyframes mousecenter {
        0%{
            background-position: 0 0%;
        }
        5%{
            background-position: 0 0%;
        }
        25%{
            background-position: 0 100%;
        }
        35%{
            background-position: 0 100%;
        }
        55%{
            background-position: 0 0%;
        }
        60%{
            background-position: 0 0%;
        }
        80%{
            background-position: 0 100%;
        }
        85%{
            background-position: 0 100%;
        }
        100%{
            background-position: 0 0%;
        }
    }
    .mouse-center1{
        animation-name:mousecenter;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        background-image:linear-gradient(to bottom,var(--s-color1),var(--s-color1),rgba(0,123,211,0.7),rgba(0,123,211,0.7),rgba(0,123,211,0.7),rgba(0,123,211,0.7),var(--s-color1),var(--s-color1));
        background-size: 100% 800%;
    }
    .mouse-down{
        width: 42px;
        height: 30px;
        border-top: 1px solid white;
        border-right: 2px solid white;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .direction{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .direction-top,.direction-left,.direction-down,.direction-right{
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        border-radius: 5px;
        font-size: 10px;
        animation-name:directiondown;
        animation-iteration-count: infinite;
        animation-duration: 4s;
    }
    .direction-top{
        animation-delay: 3s;
    }
    .direction-bottom{
        display: flex;
        flex-direction: row;
        margin-top: 2px;
    }
    .direction-left{
        animation-delay:1s;
    }
    .direction-right{
        animation-delay:2s;
    }
    .direction-down{
        margin: 0 2px;
        animation-delay:0s;
    }
    .pointer{
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: touched 1.5s infinite 0s;
      transform: translateY(2px);
    }
    @keyframes touched {
      0%{
        transform: translateY(2px);
      }
      50%{
        transform: translateY(-3px);
      }

      100%{
        transform: translateY(2px);
      }
    }
    .pointer-bottom{
      width: 30px;
      height: 20px;
      border-left: white 2px solid;
      border-bottom: white 2px solid;
      border-right: white 2px solid;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: rgba(70,71,76,1);
    }
    .pointer-top{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .pointer-top-item:nth-child(1){
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(70,71,76,1);
    }
    .pointer-top-item-top{
      width: 4.5px;
      height:4.5px;
      border-top: 1px solid rgba(0,123,211,0.3);
      border-radius:7.5px ;
      overflow: hidden;
      transform: scale(3,3);
      position: absolute;
      left: 33%;
      top: -3px;
      animation: mytouch 1.5s infinite 0s;
      box-shadow: 0 0 5px rgba(0,123,211,0);
    }
    @keyframes mytouch {
      0%{
        border-top: 1px solid rgba(0,123,211,0.3);
        box-shadow: 0 0 5px rgba(0,123,211,0);
      }
      50%{
        border-top: 1px solid rgba(0,123,211,1);
        box-shadow: 0 0 5px rgba(0,123,211,1);
      }

      100%{
        border-top: 1px solid rgba(0,123,211,0.3);
        box-shadow: 0 0 5px rgba(0,123,211,0);
      }
    }
    .pointer-top-item-bottom{
      width: 4.5px;
      height: 18px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      border-top-left-radius:7.5px ;
      border-top-right-radius:7.5px ;
    }
    .pointer-top-item:nth-child(2){
      width: 4.5px;
      height: 10px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      border-top-left-radius:7.5px ;
      border-top-right-radius:7.5px ;
      background-color: rgba(70,71,76,1);
    }
    .pointer-top-item:nth-child(3){
      width: 4.5px;
      height: 9px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      border-top-left-radius:7.5px ;
      border-top-right-radius:7.5px ;
      background-color: rgba(70,71,76,1);
    }
    .pointer-top-item:nth-child(4){
      width:4.5px;
      height: 8px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      border-top-left-radius:7.5px ;
      border-top-right-radius:7.5px ;
      background-color: rgba(70,71,76,1);
    }
</style>