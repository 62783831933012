import router from "../router";
import store from '../store'


export function myaddroute(){

    return new Promise((resolve) => {


        let routerlist = store.state.routerlist


        for(let i = 0; i<routerlist.length;i++){
            const mycomponent =import('@/views' +routerlist[i].router + '.vue')
            // let route= {
            //     path:routerlist[i].router,
            //     name:routerlist[i].name,
            //     component:mycomponent,
            //     meta:{index:routerlist[i].index},
            // }
            router.addRoute({
                path:routerlist[i].router,
                name:routerlist[i].name,
                //延迟加载
                component:()=>mycomponent,
                meta:{index:routerlist[i].index},
            })
        }

        resolve();

    });


}




