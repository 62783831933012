import component from './guide.vue'

const Index = {
    install: (Vue) => {
        Vue.component('guide', component)
    }
}

// 导出组件
export default Index
