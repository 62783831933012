<template>
  <div id="app" :style="(X?'min-width:0;width:'+Y+'px;height:'+X+'px':'min-width:1200px;')" @click="fullscreen">
    <div class="shadow" style="z-index: 99" v-if="!isFullScreen">
        <a class="sunbutton">
          START 开始
          <div class="sunbutton-right"></div>
          <div class="sunbutton-top"></div>
          <div class="sunbutton-bottom"></div>
        </a>

    </div>



    <!--    预加载每个路由第一张背景修复切换路由时出现内页下一层文字问题-->
    <img src="@/img/s1.jpg" style="display: none">
    <img src="@/img/s2.jpg" style="display: none">
    <img src="@/img/s3.jpg" style="display: none">
    <img src="@/img/s4.jpg" style="display: none">
    <img src="@/img/c1.jpg" style="display: none">
    <img src="@/img/c2.jpg" style="display: none">
    <img src="@/img/c3.jpg" style="display: none">
    <img src="@/img/n1.jpg" style="display: none">
    <img src="@/img/p1.jpg" style="display: none">
    <img src="@/img/p2.jpg" style="display: none">
    <img src="@/img/p3.jpg" style="display: none">
    <img src="@/img/p4.jpg" style="display: none">
    <img src="@/img/xh1.jpg" style="display: none">
    <img src="@/img/xh2.jpg" style="display: none">
    <img src="@/img/xh3.jpg" style="display: none">
    <img src="@/img/xh4.jpg" style="display: none">
    <img src="@/img/xhhc1.png" style="display: none">
    <img src="@/img/home000.webp" style="display: none" alt="">




    <nav1 :nav1Data="Y" ></nav1>
    <!--    <transition :name="$store.state.transitionName">-->
    <!--        <router-view/>-->
    <!--    </transition>-->
      <guide v-if="isFullScreen" :guideData="X"></guide>
<!--    <div class="guideprevent"></div>-->
    <router-view v-slot="{ Component }"  >
      <transition :name="$store.state.transitionName" >
        <component :is="Component" :componentData="Y" />
      </transition>
    </router-view>
  </div>
</template>

<script>
  import nav1 from "./components/nav1";
  export default {
    components:{
      nav1,
    },
    data(){
      return{
        X:0,
        Y:0,//给component传值，使得Y有值时，调换鼠标点击事件的XY
        isFullScreen:false,
        diybarData:{
          X:0,
        },
      }
    },
    created() {
      this.getDPR()
      //预加载
    },
    methods:{
      getDPR(){
        // let dpr = window.devicePixelRatio||1
        // let X = document.body.clientWidth
        // let Y = document.body.clientHeight
        let X = window.outerWidth
        let Y = window.outerHeight
        var ispad = navigator.userAgent.toLowerCase().match(/pad/i) == 'pad'
        if(ispad){
          if(X>Y){
            this.X = Y
            this.Y = X
          }else{
            this.X = X
            this.Y = Y
            alert('请横屏浏览')
          }

        }


      },

      fullscreen() {
        this.isFullScreen = true
        //全屏
        var el = document.documentElement
        var rfs = el.requestFullScreen||el.webkitRequestFullScreen||el.mozRequestFullScreen||el.msRequestFullScreen
        let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
        if(!isFullScreen){
          if(rfs){
            rfs.call(el)
          }
        }
      },

    },
    watch:{
      $route(to,from){
        if(to.meta.index>from.meta.index){
          this.$store.state.transitionName='right'
        }else{
          this.$store.state.transitionName='left'
        }
      }

    },

  }
</script>

<style >
  body{
    overflow: hidden;
  }

  *{
    padding: 0;
    margin: 0;
    border: 0;
    text-decoration: none;
  }
  textarea{
    resize: none;
  }
  input,textarea{
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: 'PingFang SC', 'Source Han Sans CN' !important;
    display: block;
  }
  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
  input[type='number']{
    -moz-appearance: textfield;
  }

  #app{
    /*主色*/
    --m-color:black;
    /*辅色1*/
    --s-color1:rgb(217,185,33);
    /*辅色2*/
    --s-color2:rgb(0,123,211);
    /*大标题字号*/
    --b-t-size:36px;
    /*大内容字号*/
    --b-c-size:18px;
    /*小标题字号*/
    --s-t-size:20px;
    /*小内容字号*/
    --s-c-size:14px;
    font-family: 'PingFang SC', 'Source Han Sans CN' !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background:var(--m-color);
    overflow: hidden;
    color: white;
    transform-origin: 0 0;




  }
  body,html{

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /*.skew{*/
  /*  width: 10px;*/
  /*  height: 100%;*/
  /*  position: absolute;*/
  /*  z-index: 99;*/
  /*  background: white;*/
  /*  left: -50%;*/
  /*  top: 0;*/
  /*  transform: skew(45deg);*/
  /*  animation:turncut 4s infinite;*/
  /*}*/
  /*@keyframes turncut {*/
  /*  0%,30%{*/
  /*    left: -50%;*/
  /*  }*/

  /*  70%,100%{*/
  /*    left: 150%;*/
  /*  }*/

  /*}*/

  .scrollbarCSS{
    /*立体滚动条凸出部分的颜色*/
    scrollbar-face-color:white;
    /*立体滚动条亮边的颜色（设置滚动条的颜色）*/
    scrollbar-3dlight-color:white;
    /*滚动条的基本颜色*/
    scrollbar-base-color:white;
    /*!*立体滚动条凸出部分的颜色*!*/
    /*scrollbar-face-color:#555354;*/
    /*!*上下按钮上三角箭头的颜色*!*/
    /*scrollbar-arrow-color:#555354;*/
    /*!*滚动条空白部分的颜色*!*/
    /*scrollbar-highlight-color:#555354;*/
    /*!*立体滚动条亮边的颜色（设置滚动条的颜色）*!*/
    /*scrollbar-3dlight-color:#555354;*/
    /*!*立体滚动条阴影的颜色*!*/
    /*scrollbar-shadow-color:#555354;*/
    /*!*立体滚动条强阴影的颜色*!*/
    /*scrollbar-darkshadow-color:#555354;*/
    /*!*为滚动条底板颜色设定；*!*/
    /*scrollbar-track-color:#1c6497;*/
    /*!*滚动条的基本颜色*!*/
    /*scrollbar-base-color:#1c6497;*/
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: hsla(0, 0%, 100%, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: white;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: hsla(0, 0%, 100%, 0);
  }
  button{
    box-sizing:content-box!important;
    display: inline-block;
    background-color: transparent;
    line-height: normal;
    padding-left: 0;
    padding-right: 0;
    position: static;
    border-radius:0;
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .wx1{
    position: absolute;
    bottom:100%;
    right: 50%;
    transform: translateY(0px) scaleX(0) scaleY(0);
    transform-origin: 100% 100%;
    width: 250px;
    display: flex;
    opacity: 0;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
  }
  .wx1-1{
    position: absolute;
    bottom:100%;
    right: 50%;
    transform: translateY(0px) scaleX(0) scaleY(0);
    transform-origin: 100% 100%;
    width: 250px;
    display: flex;
    opacity: 0;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
  }
  .wx2{
    position: absolute;
    bottom:100%;
    left: 50%;
    width: 250px;
    transform: translateY(0px) translateX(-50%) scaleX(0) scaleY(0);
    transform-origin: 50% 100%;
    display: flex;
    opacity: 0;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
  }
  .wx2-1{
    position: absolute;
    bottom:100%;
    left: 50%;
    width: 250px;
    transform: translateY(0px) translateX(-50%) scaleX(0) scaleY(0);
    transform-origin: 50% 100%;
    display: flex;
    opacity: 0;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
  }
  .wx1-name{
    font-size: 17px;
    color: var(--s-color1);
    margin: 20px 0 10px 0;
    padding: 0px 10px;
    text-align: center;
  }
  .wx1-phone{
    font-size: 15px;
    color: white;
    margin: 20px 0 10px 0;
  }
  .wx1code{
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }
  .wx1-remark{
    font-size: 15px;
    color: white;
    margin-bottom: 15px;
    text-align: center;
  }

  a{
    color: white;
  }
  /*页面总体布局样式*/
  .scrolllayout {
    position: absolute;
    background: var(--m-color);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
    /*animation: indexz 16s forwards;*/
    /*animation: indexz 3s forwards;*/
    transform-origin: 0 0;
    /*flex-direction: column;*/
    z-index: 0;
  }
  /*@keyframes indexz {*/
  /*  0%{*/
  /*    z-index: -1;*/
  /*  }*/
  /*  50%{*/
  /*    z-index: -1;*/
  /*  }*/
  /*  100%{*/
  /*    z-index: -10;*/
  /*  }*/
  /*}*/






  .right-enter-active,
  .right-leave-active,
  .left-enter-active,
  .left-leave-active{
    /*will-change: transform;*/
    transition: all 0.5s ease-out;
    /*position: absolute;*/
  }
  .right-enter-from{
    /*opacity: 0;*/
    transform: translateX(100vw);

  }




  .left-enter-from{
    /*opacity: 0;*/
    transform: translateX(-100vw);
  }

  /*.left-enter-to{*/
  /*  !*opacity:1;*!*/
  /*  transform: translateX(0);*/
  /*}*/

  /*.slide-right-leave-active,.slide-left-enter{*/
  /*    !*opacity: 0;*!*/
  /*    transform: translate3d(100,0,0);*/
  /*}*/
  .bac {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    right: 0 ;
    bottom: 0;
    top: 0;
  }

  .shadow {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;





    /*z-index: 1;*/
  }



  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .table{
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .table-columns-top{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 24px;
    border-bottom: 1px solid #93a9ba;
  }
  .table-columns:nth-child(1){
    width: 301px;
  }
  .table-columns{
    width: 214px;
  }
  .table-columns-icon{
    width: 35px;
    height: 35px;
    margin-left: 102px;
    cursor: pointer;
  }
  .table-columns-bottom{
    margin-top: 28px;
  }
  .table-columns-item{
    margin-bottom: 39px;
  }
  .table-columns-item:last-child{
    margin-bottom: 0;
  }
  .xhhc{
    font-size: var(--s-c-size);
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*cursor: pointer;*/
  }
  /*.xhhc1{*/
  /*  font-size: var(--s-c-size);*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  justify-content: center;*/
  /*  !*cursor: pointer;*!*/
  /*}*/
  .xhhc-left{
    background: linear-gradient(to left, #846a34 0%, #ada291 100%);
    color: var(--m-color);
    padding-left: 18px;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  .xhhc-right{
    padding-left: 15px;
    padding-right: 18px;
    padding-top: 4px;
    padding-bottom: 5px;
    background: linear-gradient(to left, #1b243e 0%, #16244b 100%);
  }

  .shadow-top,.shadow-center,.shadow-bottom,.opchange,.zhfn,.wy{
    transition:0.3s;
  }
  .zhfn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: var(--b-t-size);
  }
  .zhfn-left-text{
    color: var(--s-color2);
  }
  .zhfn-left{
    margin-right: 46px;
  }
  .zhfn-right-text{
    color: var(--s-color1);
  }


  .zhfn-left-text{
    --mycolor:var(--s-color2);
  }
  .zhfn-right-text{
    --mycolor:var(--s-color1);
  }
  .zhfn-left-text,.zhfn-right-text{
    animation: animationzh 3s infinite linear;
  }


  @keyframes animationzh {
    0%{
      text-shadow:0 0 0px var(--mycolor),
      0 0 0px var(--mycolor),
      0 0 0px var(--mycolor);

    }
    10%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 0px var(--mycolor),
      0 0 0px var(--mycolor);
    }
    20%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 2px var(--mycolor),
      0 0 0px var(--mycolor);
    }
    30%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 2px var(--mycolor),
      0 0 4px var(--mycolor);
    }
    70%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 2px var(--mycolor),
      0 0 4px var(--mycolor);
    }



    80%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 2px var(--mycolor),
      0 0 0px var(--mycolor);
    }
    90%{
      text-shadow:0 0 1px var(--mycolor),
      0 0 0px var(--mycolor),
      0 0 0px var(--mycolor);
    }
    100%{
      text-shadow:0 0 0px var(--mycolor),
      0 0 0px var(--mycolor),
      0 0 0px var(--mycolor);
    }
  }



  .wy{
    background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--b-t-size);
  }
  .guideprevent{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 990;
  }
  .sunbutton{
    font-size: 24px;
    width: 313px;
    height: 90px;
    background-image:linear-gradient(to right,rgba(0,123,211,0),rgba(0,123,211,1));
    display: inline-block;
    line-height: 90px;
    color: white;
    text-align: center;
    animation-name: sunlinear ;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
  .sunbutton-top{
    position: absolute;
    left: -1px;
    top: -1px;
    height:2px;
    background-image: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1));
    background-size: 315px 1px;
    width: 315px;
    animation: topline 9s linear infinite;
  }
  @keyframes topline {
    0%{
      opacity: 0;
      width: 0;
    }
    43.87%{
      opacity: 1;
      width: 315px;
    }
    56.13%{
      opacity: 1;
    }
    100%{
      opacity: 0;
      width: 315px;
    }
  }

  .sunbutton-right{
    position: absolute;
    right: -1px;
    top: 1px;
    height:0px;
    background-color: white;
    background-size: 2px 88px;
    width: 2px;
    animation: rightline 9s linear infinite;
  }
  @keyframes rightline {
    0%{
      height: 0px;
    }
    43.87%{
      height: 0px;
    }
    56.13%{
      height: 88px;
      opacity: 1;
    }
    100%{
      opacity: 0;
      height: 88px;
    }
  }
  .sunbutton-bottom{
    position: absolute;
    right: -1px;
    bottom: -1px;
    height:2px;
    background-image: linear-gradient(to left,rgba(255,255,255,1),rgba(255,255,255,0));
    background-size: 315px 1px;
    background-position: 100% 0;
    width: 0px;
    animation: bottomline 9s linear infinite;
  }
  @keyframes bottomline {
    0%{
      width: 0;
    }
    56.13%{
      width: 0px;
      opacity: 1;
    }
    100%{
      width: 315px;
      opacity: 0;
    }
  }
  .bar-enter-active,.bar-leave-active{
    transition: 0.5s;
  }
  .bar-enter-from{
    opacity: 0;
  }

  .bar-leave-to{
    opacity: 0;
  }
  .wx4{
    transform-origin: 100% 100%;
    position: absolute;
    bottom:100%;
    left: -50%;
    width: 250px;
    display: flex;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;

  }
</style>

